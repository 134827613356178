import type { HTMLChakraProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'

export const Logo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    height='40px'
    width='auto'
    viewBox='0 0 174 40'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20 0C8.95469 0 0 8.95469 0 20C0 31.0453 8.95469 40 20 40C31.0453 40 40 31.0453 40 20C40 8.95469 31.0453 0 20 0ZM29.527 27.4046H25.4452C24.4316 27.4046 23.4976 26.868 22.9849 25.9976L20.461 21.3076C20.461 21.3076 20.4452 21.2838 20.4372 21.2679L22.2496 20.2186V17.6192L20 16.3196L17.7504 17.6192V20.2186L19.5628 21.2679C19.5628 21.2679 19.5469 21.2917 19.539 21.3076L17.0151 25.9976C16.5024 26.872 15.5644 27.4046 14.5548 27.4046H10.473C9.56677 27.4046 9.00238 26.4189 9.46343 25.6399L18.9905 9.43959C19.4436 8.66852 20.5564 8.66852 21.0095 9.43959L30.5366 25.6359C30.9976 26.4149 30.4332 27.4006 29.527 27.4006V27.4046Z'
      fill='#3BE0FF'
    />
    <path
      d='M56.8601 9.08582H63.442L72.2416 30.8465H65.7273L64.7218 28.0246H55.5882L54.5827 30.8465H48.0684L56.868 9.08582H56.8601ZM63.0048 23.12L60.4173 15.7671C60.2146 15.2305 60.1828 15.0278 60.1471 14.8927C60.1471 14.8927 60.0795 15.1947 59.8768 15.7671L57.2893 23.12H63.0048Z'
      fill='currentColor'
    />
    <path
      d='M76.872 9.08582H86.4746C93.1558 9.08582 96.5819 12.9491 96.5819 17.6828C96.5819 20.2027 95.4412 22.7861 93.1558 24.364L97.2178 30.8465H89.9324L87.4483 26.1764C86.7766 26.244 86.407 26.2758 85.8704 26.2758H83.2512V30.8426H76.872V9.08582ZM86.5421 20.8704C88.5215 20.8704 89.8331 19.4276 89.8331 17.6788C89.8331 15.93 88.4897 14.4872 86.5739 14.4872H83.2512V20.8664H86.5421V20.8704Z'
      fill='currentColor'
    />
    <path
      d='M102.126 9.08582H108.506V19.531C108.875 18.8593 109.245 18.0882 109.714 17.3132L114.718 9.08582H122.071L115.354 18.5532L123.18 30.8426H115.723L111.594 23.5572L108.506 27.6192V30.8426H102.126V9.08582Z'
      fill='currentColor'
    />
    <path
      d='M127.818 9.08582H145.111V14.4912H134.197V17.1462H144.674V22.4841H134.197V25.4411H145.513V30.8465H127.818V9.08582Z'
      fill='currentColor'
    />
    <path
      d='M150.215 19.9682C150.215 13.7559 154.881 8.75195 161.868 8.75195C168.855 8.75195 173.553 13.7559 173.553 19.9682C173.553 26.1804 168.851 31.252 161.868 31.252C154.885 31.252 150.215 26.248 150.215 19.9682ZM166.53 16.554L162.548 14.2527C162.138 14.0143 161.634 14.0143 161.224 14.2527L157.242 16.554C156.832 16.7925 156.578 17.2297 156.578 17.6987V22.3012C156.578 22.7742 156.828 23.2114 157.242 23.4499L161.224 25.7512C161.634 25.9857 162.138 25.9857 162.548 25.7512L166.53 23.4499C166.94 23.2114 167.194 22.7742 167.194 22.3012V17.6987C167.194 17.2257 166.944 16.7885 166.53 16.554Z'
      fill='currentColor'
    />
  </chakra.svg>
)
