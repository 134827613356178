import type { HTMLChakraProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'

export const Shield = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    height='auto'
    width='auto'
    viewBox='0 0 476 579'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect x='25' y='62' width='426' height='426' rx='24' fill='#D9D9D9' fill-opacity='0.03' />
    <path
      d='M403.5 361.032C403.5 366.573 400.543 371.692 395.743 374.46L245.743 460.958C240.951 463.721 235.049 463.721 230.257 460.958L80.257 374.46C75.4573 371.692 72.5 366.573 72.5 361.032V188.016C72.5 182.477 75.456 177.359 80.2539 174.591L230.254 88.0464C235.048 85.2806 240.952 85.2806 245.746 88.0464L395.746 174.591C400.544 177.359 403.5 182.477 403.5 188.016V361.032Z'
      fill='url(#paint0_linear_137_246)'
      stroke='url(#paint1_linear_137_246)'
    />
    <path
      d='M475.5 397.92C475.5 406.321 471.015 414.083 463.737 418.279L249.737 541.656C242.473 545.844 233.527 545.844 226.263 541.656L12.2626 418.279C4.98457 414.083 0.5 406.321 0.5 397.92V151.149C0.5 142.75 4.9825 134.99 12.2579 130.793L226.258 7.35049C233.525 3.15868 242.475 3.15866 249.742 7.35048L463.742 130.793C471.017 134.99 475.5 142.75 475.5 151.149V397.92Z'
      fill='url(#paint2_linear_137_246)'
      stroke='url(#paint3_linear_137_246)'
    />
    <path
      d='M316.214 329.632C321.165 326.774 324.216 321.491 324.216 315.774V234.25C324.216 228.534 321.167 223.253 316.217 220.394L245.609 179.62C240.658 176.761 234.558 176.761 229.607 179.62L158.999 220.394C154.049 223.253 151 228.534 151 234.25V315.774C151 321.491 154.05 326.774 159.002 329.632L229.61 370.384C234.559 373.24 240.656 373.24 245.606 370.384L316.214 329.632Z'
      fill='#E0B642'
    />
    <g clip-path='url(#clip0_137_246)'>
      <path
        d='M261.906 262.519C261.906 263.319 261.601 264.119 260.991 264.728L233.977 291.914C233.366 292.524 232.566 292.829 231.768 292.829C230.969 292.829 230.168 292.519 229.563 291.914L215.031 277.402C214.426 276.797 214.113 275.996 214.113 275.019C214.113 274.22 214.418 273.42 215.029 272.81L217.238 270.602C217.863 270.156 218.488 269.863 219.445 269.863C220.402 269.863 221.047 270.156 221.672 270.762L231.773 280.863L254.371 258.266C254.981 257.655 255.781 257.35 256.58 257.35C257.379 257.35 258.179 257.655 258.789 258.266L260.998 260.475C261.613 261.094 261.906 261.894 261.906 262.519Z'
        fill='white'
      />
      <g style={{ mixBlendMode: 'overlay' }} opacity='0.4'>
        <path
          d='M279.113 241.35L241.613 225.725C240.661 225.328 239.043 225.001 238.012 225.001C236.974 225.001 235.365 225.328 234.406 225.725L196.908 241.35C193.41 242.793 191.125 246.211 191.125 249.824C191.125 300.234 228.078 325 237.98 325C247.98 325 284.875 299.961 284.875 249.824C284.875 246.211 282.59 242.793 279.113 241.35ZM261.906 262.52C261.906 263.319 261.601 264.119 260.991 264.729L233.977 291.914C233.366 292.524 232.566 292.829 231.768 292.829C230.969 292.829 230.168 292.52 229.562 291.914L215.031 277.402C214.426 276.797 214.113 275.996 214.113 275.02C214.113 274.22 214.418 273.42 215.029 272.811L217.238 270.602C217.863 270.156 218.488 269.863 219.445 269.863C220.402 269.863 221.047 270.156 221.672 270.762L231.773 280.863L254.371 258.266C254.981 257.656 255.781 257.35 256.58 257.35C257.379 257.35 258.179 257.656 258.789 258.266L260.998 260.475C261.613 261.094 261.906 261.895 261.906 262.52Z'
          fill='white'
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_137_246'
        x1='238'
        y1='83'
        x2='238'
        y2='466'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#B74024' />
        <stop offset='1' stop-color='#B74024' stop-opacity='0' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_137_246'
        x1='238'
        y1='83'
        x2='238'
        y2='466'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#FF5C00' />
        <stop offset='1' stop-color='#FF5C00' stop-opacity='0' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_137_246'
        x1='238'
        y1='0'
        x2='238'
        y2='549'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#B74024' />
        <stop offset='1' stop-color='#B74024' stop-opacity='0' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_137_246'
        x1='238'
        y1='-53.5'
        x2='238'
        y2='549'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#F90000' />
        <stop offset='1' stop-color='#FF0000' stop-opacity='0' />
      </linearGradient>
      <clipPath id='clip0_137_246'>
        <rect width='100' height='100' fill='white' transform='translate(188 225)' />
      </clipPath>
    </defs>
  </chakra.svg>
)
