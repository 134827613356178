import type { HTMLChakraProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'

export const DefiIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    height='67'
    width='auto'
    viewBox='0 0 59 67'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M52.0001 53.6951C55.7129 51.5515 58 47.59 58 43.3028V23.6717C58 19.3846 55.7129 15.4231 52.0001 13.2795L35.0001 3.46428C31.2873 1.32058 26.7127 1.32058 22.9999 3.46428L5.99987 13.2795C2.28713 15.4231 0 19.3846 0 23.6717V43.3028C0 47.59 2.28713 51.5515 5.99987 53.6951L22.9999 63.5103C26.7128 65.654 31.2873 65.654 35.0001 63.5103L52.0001 53.6951Z'
      fill='#2A17A7'
      fill-opacity='0.5'
    />
    <path
      d='M15 24.25C15 22.3168 16.5668 20.75 18.5 20.75H39.5C41.4305 20.75 43 22.3168 43 24.25V41.75C43 43.6805 41.4305 45.25 39.5 45.25H18.5C16.5668 45.25 15 43.6805 15 41.75V24.25ZM23.75 26H41.25V24.25C41.25 23.2837 40.468 22.5 39.5 22.5H23.75V26ZM22 22.5H18.5C17.5337 22.5 16.75 23.2837 16.75 24.25V26H22V22.5ZM16.75 27.75V41.75C16.75 42.718 17.5337 43.5 18.5 43.5H39.5C40.468 43.5 41.25 42.718 41.25 41.75V27.75H16.75Z'
      fill='#486FF9'
    />
  </chakra.svg>
)
