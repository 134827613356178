import type { HTMLChakraProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'

export const InfuraLogo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    height='46'
    width='auto'
    viewBox='0 0 98 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M40.5937 0.440022V2.7556L46.1316 2.18885H48.1719V5.9456L44.739 6.99814L40.0755 8.11544L40.7718 10.3824L45.241 8.666L48.1881 7.7592V13.9125H46.1478L40.6098 13.3457V15.6613H57.5152V13.3457L51.9772 13.9125H49.9046V7.7592L52.8355 8.64981L57.3209 10.3663L58.0172 8.09925L53.3698 6.98194L49.9207 5.92941V2.17266H51.9611L57.499 2.73941V0.423828L40.5937 0.440022ZM35.5415 30.0082V45.2295H37.5656V38.0237H46.3421V36.0158H37.5656V32.0323H47.4432V30.0082H35.5415ZM61.2396 39.4487C61.2396 39.6106 61.191 43.4483 57.3209 43.4483C53.7261 43.4483 53.4184 40.3878 53.4022 39.4487V30.0082H51.3781V39.4487C51.3781 42.2014 52.9326 45.4238 57.3209 45.4238C61.7092 45.4238 63.2637 42.2014 63.2637 39.4487V30.0082H61.2396V39.4487ZM80.6548 34.8175C80.6548 32.4209 78.987 30.0082 75.2626 30.0082H68.7369V45.2295H70.761V39.6268H74.6635L78.0802 45.2295H80.5253L76.9628 39.4163C77.9182 39.1896 80.6548 38.2504 80.6548 34.8175ZM70.7772 37.6027V32.0161H75.2788C76.5418 32.0161 78.6469 32.3886 78.6469 34.8175C78.6469 37.1169 76.8171 37.6027 75.2788 37.6027H70.7772ZM27.2021 30.0082V42.1043H26.7649L19.6239 30.0082H17.0006V45.2295H19.0247V33.5221H19.462L26.2792 45.2295H29.2263V30.0082H27.2021ZM0.629639 30.0082V31.8866H5.13126V43.3511H0.629639V45.2295H11.657V43.3511H7.15537V31.8866H11.657V30.0082H0.629639ZM92.2489 29.992H89.82L84.282 45.2295H86.4033L88.0712 40.6145H93.9654L95.6332 45.2295H97.7707L92.2489 29.992ZM88.7998 38.639L90.7916 33.1334H91.2773L93.2691 38.639H88.7998Z'
      fill='#FF6B4A'
    />
  </chakra.svg>
)
