import type { HTMLChakraProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'

export const ArkeoStackedLogo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    height='46'
    width='auto'
    viewBox='0 0 62 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.07463 34.7725H8.23748L12.4661 45.2294H9.3357L8.85248 43.8733H4.46345L3.98024 45.2294H0.849854L5.07845 34.7725H5.07463ZM8.02739 41.5164L6.78402 37.9831C6.68662 37.7252 6.67134 37.6278 6.65415 37.5629C6.65415 37.5629 6.62168 37.708 6.52427 37.9831L5.2809 41.5164H8.02357H8.02739Z'
      fill='white'
    />
    <path
      d='M14.6912 34.7725H19.3056C22.5162 34.7725 24.1625 36.6289 24.1625 38.9037C24.1625 40.1146 23.6144 41.356 22.5162 42.1143L24.4681 45.2294H20.9672L19.7735 42.9852C19.4507 43.0177 19.2731 43.0329 19.0153 43.0329H17.7566V45.2274H14.6912V34.7725ZM19.338 40.4354C20.2892 40.4354 20.9195 39.7421 20.9195 38.9017C20.9195 38.0614 20.2739 37.3681 19.3533 37.3681H17.7566V40.4335H19.338V40.4354Z'
      fill='white'
    />
    <path
      d='M26.8269 34.7725H29.8923V39.7918C30.07 39.469 30.2476 39.0985 30.473 38.726L32.8776 34.7725H36.411L33.1832 39.3219L36.9438 45.2274H33.3608L31.3764 41.7265L29.8923 43.6785V45.2274H26.8269V34.7725Z'
      fill='white'
    />
    <path
      d='M39.1726 34.7725H47.4827V37.37H42.2381V38.6458H47.2726V41.2109H42.2381V42.6318H47.6756V45.2294H39.1726V34.7725Z'
      fill='white'
    />
    <path
      d='M49.9352 40.0017C49.9352 37.0164 52.1774 34.6118 55.5351 34.6118C58.8928 34.6118 61.1503 37.0164 61.1503 40.0017C61.1503 42.9869 58.8909 45.424 55.5351 45.424C52.1794 45.424 49.9352 43.0194 49.9352 40.0017ZM57.7755 38.361L55.8617 37.2552C55.665 37.1406 55.4224 37.1406 55.2257 37.2552L53.3119 38.361C53.1152 38.4756 52.993 38.6857 52.993 38.9111V41.1228C52.993 41.3501 53.1133 41.5602 53.3119 41.6748L55.2257 42.7806C55.4224 42.8933 55.665 42.8933 55.8617 42.7806L57.7755 41.6748C57.9722 41.5602 58.0944 41.3501 58.0944 41.1228V38.9111C58.0944 38.6838 57.9741 38.4737 57.7755 38.361Z'
      fill='white'
    />
    <path
      d='M31.0269 0.423828C23.5378 0.423828 17.4663 6.49535 17.4663 13.9844C17.4663 21.4734 23.5378 27.5449 31.0269 27.5449C38.5159 27.5449 44.5874 21.4734 44.5874 13.9844C44.5874 6.49535 38.5159 0.423828 31.0269 0.423828ZM37.4864 19.0049H34.7188C34.0316 19.0049 33.3983 18.6411 33.0507 18.0509L31.3395 14.871C31.3395 14.871 31.3287 14.8548 31.3233 14.844L32.5521 14.1326V12.3702L31.0269 11.4889L29.5016 12.3702V14.1326L30.7304 14.844C30.7304 14.844 30.7196 14.8602 30.7142 14.871L29.003 18.0509C28.6554 18.6438 28.0194 19.0049 27.3349 19.0049H24.5673C23.9528 19.0049 23.5702 18.3366 23.8828 17.8084L30.3424 6.82413C30.6496 6.30132 31.4041 6.30132 31.7113 6.82413L38.1709 17.8057C38.4835 18.3339 38.1009 19.0022 37.4864 19.0022V19.0049Z'
      fill='#3BE0FF'
    />
  </chakra.svg>
)
