import type { HTMLChakraProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'

export const PoktLogo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    height='46'
    width='auto'
    viewBox='0 0 53 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M45.8798 36.3078V45.4193C47.3492 45.4193 48.5396 44.228 48.5396 42.7594V33.6479C47.0711 33.6479 45.8798 34.8384 45.8798 36.3078Z'
      fill='#FAFAFA'
    />
    <path
      d='M41.0935 32.4951H49.9487C51.4304 32.4951 52.6313 31.2942 52.6313 29.8125H43.7761C42.2944 29.8125 41.0935 31.0134 41.0935 32.4951Z'
      fill='#FAFAFA'
    />
    <path
      d='M9.67358 29.8486C9.37357 30.0883 8.93973 30.4478 8.5575 30.7679C8.1088 31.1431 7.97935 31.7624 8.20326 32.303C8.39744 32.7718 8.5024 33.2922 8.5024 33.8371V33.8809C8.5024 34.7713 8.23038 35.5952 7.73794 36.2346C7.73094 36.2433 7.72307 36.2521 7.71607 36.2608C7.09681 36.9562 6.36121 37.3472 5.50841 37.4346C5.38508 37.4478 5.24601 37.4329 5.11831 37.4329C5.11831 37.4329 4.44831 37.4381 3.70134 37.4329H3.68997C2.18729 37.4329 0.968872 38.6504 0.968872 40.1531V45.4239C2.46281 45.4239 3.67335 44.2133 3.67335 42.7194V39.8479C4.01272 39.8479 4.7387 39.8479 4.96086 39.8479C8.52427 39.8479 11.1474 37.1154 11.1474 33.8363V33.7925C11.1474 32.2837 10.6025 30.9035 9.67358 29.8486Z'
      fill='#FAFAFA'
    />
    <path
      d='M31.5543 29.8471V43.1971C31.5543 44.4234 30.5554 45.4162 29.3291 45.4162H28.9207V32.0714C28.9207 30.8451 29.9143 29.8462 31.1406 29.8462H31.5543V29.8471Z'
      fill='#FAFAFA'
    />
    <path
      d='M27.5588 37.662C27.5702 38.7177 27.3716 39.7227 26.9684 40.67C26.5652 41.6233 26.015 42.4525 25.3109 43.1619C24.9138 43.559 24.4826 43.9115 24.0112 44.2176C23.6482 44.4564 23.2563 44.6716 22.8479 44.8535C22.5925 44.9672 22.3257 45.0695 22.0589 45.1483C21.7694 45.2392 21.4746 45.3127 21.179 45.3695C21.1676 45.3695 21.1624 45.3643 21.1624 45.3582L21.151 43.8258V43.775C21.1562 43.2467 21.4458 42.7875 21.8717 42.5321C21.9225 42.5041 21.9793 42.4753 22.0423 42.4473C22.6038 42.1753 23.098 41.8228 23.5292 41.3916C24.0059 40.9149 24.3803 40.3524 24.6532 39.7113C24.9261 39.0702 25.0616 38.3888 25.0564 37.6795V37.6803C25.0616 36.971 24.9261 36.2896 24.6532 35.6485C24.3812 35.0073 24.0059 34.4449 23.5292 33.9682C23.098 33.537 22.6038 33.1845 22.0423 32.9125C21.9802 32.8845 21.9233 32.8557 21.8717 32.8277C21.4458 32.5723 21.1562 32.1122 21.151 31.5848V31.534L21.1624 30.0016C21.1624 29.9964 21.1676 29.9902 21.179 29.9902C21.4737 30.0471 21.7694 30.1206 22.0589 30.2115C22.3257 30.2911 22.5925 30.3935 22.8479 30.5063C23.2563 30.6882 23.6482 30.9034 24.0112 31.1422C24.4826 31.4483 24.9138 31.8008 25.3109 32.1979C26.015 32.9073 26.5652 33.7364 26.9684 34.6898C27.3716 35.638 27.5702 36.6421 27.5588 37.6978V37.662Z'
      fill='#FAFAFA'
    />
    <path
      d='M12.0448 37.662C12.0334 38.7177 12.232 39.7227 12.6352 40.67C13.0384 41.6233 13.5886 42.4525 14.2927 43.1619C14.6898 43.559 15.121 43.9115 15.5924 44.2176C15.9554 44.4564 16.3473 44.6716 16.7557 44.8535C17.0111 44.9672 17.2779 45.0695 17.5447 45.1483C17.8342 45.2392 18.129 45.3127 18.4246 45.3695C18.436 45.3695 18.4412 45.3643 18.4412 45.3582L18.4526 43.8258V43.775C18.4474 43.2467 18.1578 42.7875 17.7319 42.5321C17.6811 42.5041 17.6243 42.4753 17.5613 42.4473C16.9998 42.1753 16.5056 41.8228 16.0744 41.3916C15.5977 40.9149 15.2233 40.3524 14.9504 39.7113C14.6775 39.0702 14.542 38.3888 14.5472 37.6795V37.6803C14.542 36.971 14.6775 36.2896 14.9504 35.6485C15.2224 35.0073 15.5977 34.4449 16.0744 33.9682C16.5056 33.537 16.9998 33.1845 17.5613 32.9125C17.6234 32.8845 17.6803 32.8557 17.7319 32.8277C18.1578 32.5723 18.4474 32.1122 18.4526 31.5848V31.534L18.4412 30.0016C18.4412 29.9964 18.4351 29.9902 18.4246 29.9902C18.1298 30.0471 17.8342 30.1206 17.5447 30.2115C17.2779 30.2911 17.0111 30.3935 16.7557 30.5063C16.3473 30.6882 15.9554 30.9034 15.5924 31.1422C15.121 31.4483 14.6898 31.8008 14.2927 32.1979C13.5886 32.9073 13.0384 33.7364 12.6352 34.6898C12.232 35.638 12.0334 36.6421 12.0448 37.6978V37.662Z'
      fill='#FAFAFA'
    />
    <path
      d='M33.3508 38.2479C33.1296 37.8622 33.1121 37.3968 33.294 36.9936L36.7481 31.1062C37.0988 30.3391 37.8641 29.8467 38.7073 29.8467H40.2231L40.0587 30.2158L35.9617 37.64L41.3418 45.4167H39.456C38.6811 45.4167 37.9665 45.0003 37.5834 44.3268L33.3508 38.2479Z'
      fill='#FAFAFA'
    />
    <path
      d='M30.1303 0.923264V5.23189C30.1303 6.62786 28.9976 7.75968 27.6017 7.75881H27.5649C26.8643 7.75443 26.2345 7.75881 26.2345 7.75881C26.1147 7.75881 25.9835 7.74569 25.8681 7.75706C25.08 7.8384 24.3977 8.19614 23.8222 8.83028C23.8065 8.84777 23.7907 8.86614 23.775 8.88451C23.3131 9.48453 23.0569 10.2586 23.0569 11.0948V11.1359C23.0569 11.6738 23.1662 12.1846 23.3665 12.6421C23.5668 13.1004 23.4645 13.6322 23.0814 13.9532C22.7053 14.2681 22.2583 14.639 21.9574 14.8795C21.0854 13.8894 20.5737 12.5931 20.5737 11.1761V11.135C20.5737 8.05707 23.0368 5.49079 26.3824 5.49079C26.5914 5.49079 27.2728 5.49079 27.5912 5.49079V0.456191C27.32 0.436948 27.048 0.423828 26.7725 0.423828C20.3952 0.423828 15.226 5.59312 15.226 11.9703C15.226 17.179 18.6756 21.5803 23.4137 23.0192V18.7438C23.4137 17.3329 24.5578 16.1889 25.9686 16.1897H25.98C26.6806 16.195 27.3104 16.1897 27.3104 16.1897C27.4302 16.1897 27.5614 16.2029 27.6769 16.1906C28.465 16.1093 29.1472 15.7524 29.7227 15.1174C29.7393 15.0999 29.7542 15.0815 29.77 15.0632C30.2318 14.4631 30.4872 13.6891 30.4872 12.8529V12.8118C30.4872 12.3928 30.3114 11.8339 30.1347 11.3799C29.9388 10.877 30.0892 10.2988 30.5038 9.95248C30.8615 9.65334 31.2228 9.35945 31.5875 9.06819C32.4596 10.0583 32.9712 11.3546 32.9712 12.7715V12.8126C32.9712 15.8915 30.5082 18.4569 27.1626 18.4569C26.9535 18.4569 26.2722 18.4569 25.9538 18.4569V23.4845C26.2249 23.5037 26.4969 23.5169 26.7725 23.5169C33.1497 23.5169 38.319 18.3476 38.319 11.9703C38.319 6.76343 34.8693 2.3621 30.1303 0.923264Z'
      fill='#1D8AED'
    />
  </chakra.svg>
)
