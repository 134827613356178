import type { HTMLChakraProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'

export const DexIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    height='67'
    width='auto'
    viewBox='0 0 59 67'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M52.5001 53.6951C56.2129 51.5515 58.5 47.59 58.5 43.3028V23.6717C58.5 19.3846 56.2129 15.4231 52.5001 13.2795L35.5001 3.46428C31.7872 1.32058 27.2127 1.32058 23.4999 3.46428L6.49987 13.2795C2.78713 15.4231 0.5 19.3846 0.5 23.6717V43.3028C0.5 47.59 2.78713 51.5515 6.49987 53.6951L23.4999 63.5103C27.2128 65.654 31.7873 65.654 35.5001 63.5103L52.5001 53.6951Z'
      fill='#3BE0FF'
      fill-opacity='0.2'
    />
    <g clip-path='url(#clip0_155_63)'>
      <path
        d='M22.8765 46.0159C23.3906 46.53 24.2218 46.53 24.7304 46.0159C25.239 45.5019 25.2445 44.6706 24.7304 44.162L21.7226 41.1542L40.4312 41.1487C41.1585 41.1487 41.7437 40.5636 41.7437 39.8362C41.7437 39.1089 41.1585 38.5237 40.4312 38.5237H21.7226L24.7304 35.5159C25.2445 35.0019 25.2445 34.1706 24.7304 33.662C24.2164 33.1534 23.3851 33.1479 22.8765 33.662L17.6265 38.9065C17.1125 39.4206 17.1125 40.2519 17.6265 40.7604L22.8765 46.0104V46.0159ZM41.3609 26.7659C41.875 26.2519 41.875 25.4206 41.3609 24.912L36.1109 19.6565C35.5968 19.1425 34.7656 19.1425 34.257 19.6565C33.7484 20.1706 33.7429 21.0019 34.257 21.5104L37.2648 24.5183L18.5562 24.5237C17.8289 24.5237 17.2437 25.1089 17.2437 25.8362C17.2437 26.5636 17.8289 27.1487 18.5562 27.1487H37.2648L34.257 30.1565C33.7429 30.6706 33.7429 31.5019 34.257 32.0104C34.771 32.519 35.6023 32.5245 36.1109 32.0104L41.3609 26.7604V26.7659Z'
        fill='#3BE0FF'
      />
    </g>
    <defs>
      <clipPath id='clip0_155_63'>
        <rect width='24.5' height='28' fill='white' transform='translate(17.2437 18.8364)' />
      </clipPath>
    </defs>
  </chakra.svg>
)
