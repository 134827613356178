import type { HTMLChakraProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'

export const GamingIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    height='67'
    width='auto'
    viewBox='0 0 59 67'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M52.0001 54.6697C55.7129 52.5261 58 48.5646 58 44.2775V24.6464C58 20.3592 55.7129 16.3977 52.0001 14.2541L35.0001 4.43889C31.2873 2.29519 26.7127 2.29519 22.9999 4.43889L5.99987 14.2541C2.28713 16.3977 0 20.3592 0 24.6464V44.2775C0 48.5646 2.28713 52.5261 5.99987 54.6697L22.9999 64.4849C26.7128 66.6286 31.2873 66.6286 35.0001 64.4849L52.0001 54.6697Z'
      fill='#F6E05E'
      fill-opacity='0.2'
    />
    <path
      d='M18.3333 22.8635C18.3333 22.3746 18.7333 21.9746 19.2222 21.9746H21C21.4889 21.9746 21.8889 22.3746 21.8889 22.8635V23.7524H24.5556C25.0444 23.7524 25.4444 24.1524 25.4444 24.6413V27.3079H32.5556V24.6413C32.5556 24.1524 32.9556 23.7524 33.4444 23.7524H36.1111V22.8635C36.1111 22.3746 36.5111 21.9746 37 21.9746H38.7778C39.2667 21.9746 39.6667 22.3746 39.6667 22.8635V24.6413C39.6667 25.1302 39.2667 25.5302 38.7778 25.5302H36.1111V27.3079V29.0857H38.7778C39.2667 29.0857 39.6667 29.4857 39.6667 29.9746V32.6413H41.4444V28.1968C41.4444 27.7079 41.8444 27.3079 42.3333 27.3079H44.1111C44.6 27.3079 45 27.7079 45 28.1968V35.3079C45 35.7968 44.6 36.1968 44.1111 36.1968H41.4444V40.6413C41.4444 41.1302 41.0444 41.5302 40.5556 41.5302H37.8889V45.9746C37.8889 46.4635 37.4889 46.8635 37 46.8635H34.3333H31.6667C31.1778 46.8635 30.7778 46.4635 30.7778 45.9746V44.1968C30.7778 43.7079 31.1778 43.3079 31.6667 43.3079H34.3333V41.5302H23.6667V43.3079H26.3333C26.8222 43.3079 27.2222 43.7079 27.2222 44.1968V45.9746C27.2222 46.4635 26.8222 46.8635 26.3333 46.8635H23.6667H21C20.5111 46.8635 20.1111 46.4635 20.1111 45.9746V41.5302H17.4444C16.9556 41.5302 16.5556 41.1302 16.5556 40.6413V36.1968H13.8889C13.4 36.1968 13 35.7968 13 35.3079V28.1968C13 27.7079 13.4 27.3079 13.8889 27.3079H15.6667C16.1556 27.3079 16.5556 27.7079 16.5556 28.1968V32.6413H18.3333V29.9746C18.3333 29.4857 18.7333 29.0857 19.2222 29.0857H21.8889V27.3079V25.5302H19.2222C18.7333 25.5302 18.3333 25.1302 18.3333 24.6413V22.8635ZM21.8889 33.5302V37.0857C21.8889 37.5746 22.2889 37.9746 22.7778 37.9746H24.5556C25.0444 37.9746 25.4444 37.5746 25.4444 37.0857V33.5302C25.4444 33.0413 25.0444 32.6413 24.5556 32.6413H22.7778C22.2889 32.6413 21.8889 33.0413 21.8889 33.5302ZM32.5556 33.5302V37.0857C32.5556 37.5746 32.9556 37.9746 33.4444 37.9746H35.2222C35.7111 37.9746 36.1111 37.5746 36.1111 37.0857V33.5302C36.1111 33.0413 35.7111 32.6413 35.2222 32.6413H33.4444C32.9556 32.6413 32.5556 33.0413 32.5556 33.5302Z'
      fill='#F6E05E'
    />
  </chakra.svg>
)
