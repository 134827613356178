import type { HTMLChakraProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'

export const LavaLogo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    height='46'
    width='auto'
    viewBox='0 0 51 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M28.34 45.1126L23.0715 32.8942H26.0607L29.5357 41.1394L32.9857 32.8942H35.9749L30.7065 45.1126H28.34ZM35.9749 39.0843C35.9749 42.5966 38.4908 45.4239 42.0779 45.4239C44.0084 45.4239 45.5279 44.6642 46.2254 43.3439C46.4621 44.4774 47.3837 45.4239 48.9282 45.4239C49.4014 45.4239 49.9121 45.3368 50.2982 45.1624V42.8707C50.0865 42.8956 49.9121 42.908 49.7377 42.908C49.4139 42.908 49.1897 42.7959 49.0527 42.5842C48.9157 42.3725 48.841 41.9988 48.841 41.4881V32.8817H45.9514V34.7624C45.4283 33.4173 43.7593 32.6202 42.0654 32.6202C38.6029 32.6202 35.9749 35.4599 35.9749 39.0843ZM38.8645 39.0843C38.8645 37.2784 39.9855 35.2606 42.4515 35.2606C44.5066 35.2606 46.213 36.7926 46.213 38.9722C46.213 41.1519 44.5066 42.6838 42.4515 42.6838C40.3965 42.6838 38.8645 41.0896 38.8645 39.0843ZM5.13632 42.4845C4.3392 42.4845 3.79118 41.9739 3.79118 40.8031V28.4727H0.901611V41.1768C0.901611 43.6678 2.24675 45.1001 4.73775 45.1001H7.75187V42.4721L5.13632 42.4845ZM8.76073 39.0719C8.76073 42.5842 11.2766 45.4115 14.8637 45.4115C16.7942 45.4115 18.3137 44.6517 19.0112 43.3315C19.2479 44.4649 20.1695 45.4115 21.7139 45.4115C22.1872 45.4115 22.6979 45.3243 23.084 45.1499V42.8707C22.8847 42.8956 22.6979 42.908 22.5235 42.908C22.1997 42.908 21.9755 42.7959 21.8385 42.5842C21.7015 42.3725 21.6392 41.9988 21.6392 41.4881V32.8942H18.7496V34.7749C18.2265 33.4298 16.5576 32.6326 14.8637 32.6326C11.3887 32.6202 8.76073 35.4599 8.76073 39.0719ZM11.6503 39.0719C11.6503 37.2659 12.7712 35.2482 15.2373 35.2482C17.2924 35.2482 18.9987 36.7802 18.9987 38.9598C18.9987 41.1394 17.2924 42.6714 15.2373 42.6714C13.1823 42.6714 11.6503 41.0896 11.6503 39.0719Z'
      fill='white'
    />
    <path
      d='M37.5442 17.6738H36.9838L32.7117 15.5564L32.4626 15.1579L32.2135 14.7593V8.45708L32.4626 8.30762L38.3414 17.2752L37.5442 17.6738Z'
      fill='#FFA80A'
    />
    <path
      d='M25.5377 18.5835H24.9772L18.6127 15.4323V15.1583L18.8618 14.7598L25.301 11.5713L25.5377 11.7332L25.7868 11.5713L32.2135 14.7598L32.4626 15.1583V15.4573L26.0981 18.5835H25.5377Z'
      fill='#95001A'
    />
    <path
      d='M32.4626 15.1582L32.7117 15.5568V19.8413L32.4626 20.2025L30.6192 21.1117H30.0588L25.5376 18.8698V18.5833L32.4626 15.1582Z'
      fill='#520033'
    />
    <path
      d='M32.4626 8.30785L27.2938 0.423828H25.5376V4.87027L25.7867 5.26883L32.2135 8.45731L32.4626 8.30785Z'
      fill='#FFE50A'
    />
    <path
      d='M18.6127 8.30785L23.7815 0.423828H25.5377V4.87027L25.3384 5.24392L18.8618 8.45731L18.6127 8.30785Z'
      fill='#FFA80A'
    />
    <path d='M37.5443 17.6741L32.4626 20.2025V15.1582L37.5443 17.6741Z' fill='#6D0044' />
    <path
      d='M13.531 17.6738H14.0915L18.3636 15.5564L18.6127 15.1579L18.8618 14.7593V8.45708L18.6127 8.30762L12.7339 17.2752L13.531 17.6738Z'
      fill='#FF3417'
    />
    <path
      d='M18.6126 15.1582L18.3635 15.5568V19.8413L18.6126 20.2025L20.456 21.1117H21.0164L25.5376 18.8698V18.5833L18.6126 15.1582Z'
      fill='#40004D'
    />
    <path d='M13.531 17.6741L18.6127 20.2025V15.1582L13.531 17.6741Z' fill='#6D0044' />
    <path
      d='M25.5376 23.6273L30.6192 21.1114L25.5376 18.583L20.4559 21.1114L25.5376 23.6273Z'
      fill='#6D0074'
    />
    <path
      d='M18.6127 15.158L25.5377 11.7328L25.7868 11.5709V5.26868L25.5377 4.87012L18.6127 8.3077V15.158Z'
      fill='#D7001F'
    />
    <path d='M32.4626 15.158L25.5376 11.7328V4.87012L32.4626 8.3077V15.158Z' fill='#EF8109' />
  </chakra.svg>
)
